@import 'https://cdn.jsdelivr.net/npm/bulma@0.9.2/css/bulma.min.css';

#map,
.table_wrapper {
  height: 71vh;
}

/* Style for the map container */
#map {
  border-radius: 12px;
  overflow: hidden; /* Ensures child elements don't overflow the rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease;
}

#map:hover {
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);
}

/* Make sure the Google Map component inherits the rounded corners */
#map > div {
  border-radius: inherit;
}

/* Fix any potential issues with Google Map controls and rounded corners */
.gm-style {
  border-radius: inherit;
}

.table_wrapper {
  width: 100%;
  overflow: auto;
  background: white;
  padding: 16px;
  border-radius: 0;
  box-shadow: none;
}

/* Modern Material Design inspired table styles */
.table {
  border: none;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 14px;
}

.table th {
  border: none;
  padding: 16px 8px 8px 8px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.5px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
}

.table td {
  border: none;
  padding: 16px 8px;
  color: rgba(0, 0, 0, 0.7);
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  transition: background-color 0.2s ease;
}

.table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

/* Style for the selected row */
.table tbody tr.selected-row {
  background-color: #fffcb3 !important;
}

/* Customize dropdown for column selection */
#table-dropdown {
  margin-bottom: 12px;
}

#table-dropdown .button {
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  font-weight: 500;
  background-color: white;
  transition: all 0.2s ease;
  border: 1px solid rgba(0,0,0,0.12);
}

#table-dropdown .button:hover {
  box-shadow: 0 2px 4px rgba(0,0,0,0.14);
}

#table-dropdown .dropdown-content {
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.12);
  padding: 8px 16px;
  margin-bottom: 4px;
}

/* Make filter inputs more visible */
.table th input.input.is-small {
  border: 1px solid rgba(0,0,0,0.23);
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.08);
  padding: 6px 10px;
  font-size: 13px;
  margin-top: 8px;
  transition: all 0.2s ease;
  background-color: #f9f9f9;
  width: 100%;
}

.table th input.input.is-small:focus {
  border: 1px solid #3273dc;
  box-shadow: 0 0 0 2px rgba(50, 115, 220, 0.2);
  background-color: white;
}

.table th select.select.is-small {
  border: 1px solid rgba(0,0,0,0.23);
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.08);
  font-size: 13px;
  margin-top: 8px;
  padding: 6px 10px;
  background-color: #f9f9f9;
  width: 100%;
}

.table th select.select.is-small:focus {
  border: 1px solid #3273dc;
  box-shadow: 0 0 0 2px rgba(50, 115, 220, 0.2);
  background-color: white;
}

/* Remove hover effect on table wrapper */

.columns.is-multiline {
  display: flex;
  flex-wrap: wrap;
}

.column {
  padding: 10px;
}

/* Add a little spacing between map and table on mobile */
@media screen and (max-width: 768px) {
  .map.column {
    margin-bottom: 20px;
  }
}

.navbar {
  padding: 0.5rem 1rem;
}

.navbar-item,
.navbar-item-centered {
  padding: 0.5rem 0.75rem;
}

.navbar-item-centered {
  display: flex;
  align-items: center;
  text-align: center;
  color: rgb(0, 0, 0);
}

.table-cell-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.title-subtitle-container .title {
  font-size: 1.25rem;
}

.title-subtitle-container .subtitle {
  font-size: 0.875rem;
}

.navbar-burger {
  font-size: 1.5em;
}

a[href=''] {
  display: none;
}

.is-danger {
  color: #ff3860;
}

.is-link {
  color: #3273dc;
}

.is-black {
  color: #000000;
}
